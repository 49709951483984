<template>
  <div>
    <AuditEvaluation
      v-if="auditTemplate && auditTemplateType"
      v-model="caseEvaluation"
      :type="auditTemplateType"
      :evaluation-rules="auditTemplate.evaluation"
      :disabled="!batchUnderReview"
      :passed="isEvaluationFilled"
      class="mb-3"
    />
    <v-card
      v-if="batchUnderReview"
      class="pa-5 py-8"
      :class="{ 'mb-3': batchUnderReview }"
    >
      <div class="d-flex flex-start mb-8 flex-column">
        <div class="d-flex">
          <h4 class="text-body-1 font-weight-bold mr-5">Audit Status</h4>

          <v-tooltip bottom color="primary">
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <CaseStatusChipSelect
                  disabled
                  :label="''"
                  :status="auditStatusUnderReview"
                  :items="AuditCaseStatuses[BatchTypes.docReview]"
                />
              </div>
            </template>
            <span>Status changes after answering audit questions.</span>
          </v-tooltip>
        </div>

        <div v-if="batchUnderReview" class="d-flex">
          <StatisticItem
            :value="auditScore"
            :max-width="'none'"
            is-percent
            text="Total Case Score"
            class="mt-5"
          />
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import { useBatchReviewStore } from "@/stores/batchReview";
import AuditEvaluation from "../case/AuditEvaluation.vue";
import { mapActions, mapState } from "pinia";
import { AuditCaseStatuses } from "@/views/case/constants";
import { BatchTypes, CasesStatuses } from "@/misc/constants";
import CaseStatusChipSelect from "../case/CaseStatusChipSelect.vue";
import { caluclateGeneralAuditScore } from "@/helpers/audit";
import StatisticItem from "@/components/common/StatisticItem.vue";

export default {
  name: "GeneralAnswerForm",

  components: {
    AuditEvaluation,
    CaseStatusChipSelect,
    StatisticItem,
  },

  props: {
    batch: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      AuditCaseStatuses,
      BatchTypes,
      caseEvaluation: null,
      auditScore: 0,
      auditStatusUnderReview: null,
    };
  },

  computed: {
    ...mapState(useBatchReviewStore, [
      "batchUnderReview",
      "auditTemplateType",
      "auditTemplate",
      "audit",
    ]),
    isEvaluationFilled() {
      if (!this.caseEvaluation) return false;

      return Object.values(this.caseEvaluation).every(Boolean);
    },
  },

  watch: {
    isEvaluationFilled(val) {
      this.setGeneralBatchProceeded(val);
    },
    caseEvaluation() {
      this.auditScore = caluclateGeneralAuditScore(this.caseEvaluation);

      if (this.auditScore >= 90) {
        this.auditStatusUnderReview = CasesStatuses.compliant;
      } else if (this.auditScore >= 75) {
        this.auditStatusUnderReview = CasesStatuses.partiallyCompliant;
      } else {
        this.auditStatusUnderReview = CasesStatuses.noncompliant;
      }

      this.setGeneralBatchStats({
        caseEvaluation: this.caseEvaluation,
        caseGrade: {
          score: this.auditScore,
          status: this.auditStatusUnderReview,
        },
      });
    },
  },

  async mounted() {
    await this.initialize();
  },

  methods: {
    ...mapActions(useBatchReviewStore, [
      "getAudit",
      "fetchTemplateFromAudit",
      "fetchBatchFromAudit",
      "setGeneralBatchProceeded",
      "setGeneralBatchStats",
    ]),
    async initialize() {
      if (this.batch?.auditIds?.length) await this.getCaseAuditByBatchId();

      if (this.audit?.caseEvaluation) {
        this.caseEvaluation = this.audit.caseEvaluation;
      }
    },
    async getCaseAuditByBatchId() {
      // fetch first audit, general batch contains only one audit
      try {
        await this.getAudit(this.batch?.auditIds[0]?._id);
        await this.fetchTemplateFromAudit();
      } catch (e) {
        this.$notify({
          type: "error",
          title: "Fetch audit",
          text: e?.response?.data?.message || e.message || JSON.stringify(e),
        });
      }
    },
  },
};
</script>
