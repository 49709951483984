<template>
  <div class="batch-report__chart py-6 px-7">
    <h2
      class="batch-report__chart-title font-weight-bold text-h6 text-left pb-10"
    >
      Batch Statistic
    </h2>
    <div>
      <apexchart
        type="pie"
        :height="285"
        :options="diagramOptions"
        :series="series"
      />
    </div>
  </div>
</template>

<script>
import {
  BatchTypes,
  CasesStatusColors,
  CasesStatuses,
} from "../../misc/constants";

export default {
  name: "BatchReportDiagram",

  props: {
    statisticData: {
      type: Object,
      required: true,
    },
    batchType: {
      type: String,
      required: true,
    },
  },

  data() {
    const specialAuditStatuses = [
      CasesStatuses.appropriate,
      CasesStatuses.clinicalQuery,
      CasesStatuses.clinicalConcern,
    ];
    const auditStatuses = [
      CasesStatuses.appropriate,
      CasesStatuses.groupReview,
    ];
    const docReviewStatuses = [
      CasesStatuses.compliant,
      CasesStatuses.partiallyCompliant,
      CasesStatuses.noncompliant,
    ];
    const groupReviewStatuses = Object.values(CasesStatuses).filter(
      (st) =>
        st !== CasesStatuses.clinicalQuery || st !== CasesStatuses.reviewNeeded
    );

    const diagramOptions = {
      chart: {
        width: "100%",
        type: "pie",
      },
      legend: {
        position: "bottom",
        itemMargin: {
          vertical: 12,
          horizontal: 7,
        },
      },
      dataLabels: {
        enabled: false,
      },
    };

    return {
      diagramOptions,
      specialAuditStatuses,
      auditStatuses,
      groupReviewStatuses,
      series: [],
      docReviewStatuses,
    };
  },

  mounted() {
    this.initialize();
  },

  methods: {
    initialize() {
      let statuses;
      switch (this.batchType) {
        case BatchTypes.specialAudit:
          statuses = this.specialAuditStatuses;
          break;
        case BatchTypes.audit:
          statuses = this.auditStatuses;
          break;
        case BatchTypes.groupReview:
          statuses = this.groupReviewStatuses;
          break;
        case BatchTypes.docReview:
          statuses = this.docReviewStatuses;
          break;
      }
      const data = statuses.reduce(
        (acc, status) => {
          acc.colors.push(CasesStatusColors[status]);
          acc.labels.push(`${status}, %`);
          acc.series.push(this.statisticData[status] || 0);
          return acc;
        },
        { colors: [], labels: [], series: [] }
      );

      const statusesCount = data.series.reduce((a, b) => a + b, 0);

      this.diagramOptions = {
        ...this.diagramOptions,
        colors: data.colors,
        labels: data.labels,
      };
      this.series = data.series.map((count) =>
        Number.parseFloat(((count / statusesCount) * 100).toFixed(2))
      );
    },
  },
};
</script>
