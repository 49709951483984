<template>
  <section class="audit-result">
    <v-data-table
      id="audit-result-table"
      :headers="tableHeaders"
      :items="items"
      disable-pagination
      disable-sort
      hide-default-footer
    >
      <template v-slot:item="{ item }">
        <tr v-if="item.isTitle" class="title-row">
          <td style="min-width: 50px"></td>
          <td class="font-weight-bold" colspan="4">{{ item.text }}</td>
        </tr>
        <tr v-else :class="{ 'failed-row': isFailedResult(item) }">
          <td style="min-width: 50px">{{ item.mark }}.</td>
          <td class="font-weight-bold">{{ item.text }}</td>
          <td style="min-width: 208px">{{ item.amount }}</td>
          <td style="min-width: 178px">{{ item.percent }}%</td>
          <td style="min-width: 100px">
            {{ getTargetValue(item) }}
          </td>
        </tr>
      </template>
    </v-data-table>
  </section>
</template>

<script>
export default {
  name: "BatchAuditResult",

  props: {
    evaluationResult: {
      type: Array,
      default: () => [],
    },
    sections: {
      type: Array,
      required: true,
    },
  },

  data() {
    const tableHeaders = [
      {
        text: "",
        sortable: false,
        value: "mark",
      },
      {
        text: "Criteria",
        sortable: false,
        value: "text",
      },
      {
        text: "Number of Patients (N)",
        sortable: false,
        value: "amount",
      },
      {
        text: "Total % of Patients",
        sortable: false,
        value: "percent",
      },
      {
        text: "Target, %",
        sortable: false,
        value: "target",
      },
    ];

    return {
      tableHeaders,
    };
  },

  computed: {
    evaluationMapping() {
      return this.evaluationResult.reduce((acc, result) => {
        const { name, amount, percent } = result;
        acc[name] = { amount, percent };
        return acc;
      }, {});
    },
    items() {
      if (!this.evaluationResult?.length) return [];
      let criteriaIndex = 0;
      return this.sections.reduce((acc, section) => {
        const titleItem = {
          text: section?.title,
          isTitle: true,
        };
        const criteriaItems = section?.criteria.map((criteria, ind) => ({
          mark: String.fromCharCode(ind + criteriaIndex + 65),
          text: criteria?.text,
          amount: this.evaluationMapping[criteria._id]?.amount,
          percent: this.evaluationMapping[criteria._id]?.percent,
          target: criteria.target,
        }));
        acc.push(titleItem, ...criteriaItems);
        criteriaIndex += criteriaItems.length;
        return acc;
      }, []);
    },
  },

  methods: {
    getTargetValue({ target }) {
      if (!target) return "-";
      const { min, max = 100 } = target;
      return min === max ? `${min}%` : `${min}% - ${max}%`;
    },
    isFailedResult({ target, percent }) {
      if (!target) return false;
      const { min = 0, max = 100 } = target;
      return max === min
        ? percent !== max
        : !(max >= percent && percent >= min);
    },
  },
};
</script>

<style lang="scss" scoped>
.title-row {
  background-color: #c6f2f4;
}

.failed-row {
  background-color: #fff2f1;
}
</style>
