export const SpecialAuditTableHeaders = [
  {
    text: "Case Reference",
    align: "start",
    value: "caseReference",
    sortable: false,
  },
  {
    text: "Clinician Name",
    value: "clinicians",
    sortable: false,
  },
  {
    text: "Prescriptions",
    value: "prescriptions",
    sortable: false,
  },
  {
    text: "Clinical Codes",
    value: "clinicalCodes",
    sortable: false,
  },
  {
    text: "Age",
    value: "age",
  },
  {
    text: "Gender",
    value: "sex",
  },
  {
    text: "Location",
    value: "locationName",
    sortable: false,
  },
  {
    text: "Audit",
    value: "audit",
    sortable: false,
  },
  { text: "Case Date", value: "caseDate" },
  {
    text: "Import Date",
    value: "createdAt",
  },
];

export const ClinicianAuditTableHeaders = [
  {
    text: "Case Reference",
    align: "start",
    value: "case.caseReference",
    sortable: false,
  },
  {
    text: "Clinician Name",
    value: "clinicianName",
    sortable: true,
  },
  {
    text: "Status",
    value: "audit.status",
    sortable: false,
  },
  {
    text: "Case Score, %",
    value: "score",
    sortable: false,
  },
  {
    text: "Location",
    value: "locationName",
    sortable: false,
  },
  { text: "Case Date", value: "case.caseDate" },
  {
    text: "Import Date",
    value: "case.createdAt",
  },
];

export const FILE_SIZE_LIMIT = 10097152;
export const ReportModalButtons = ["cancel", "save"];
export const REPORT_DESCRIPTION_DEFAULT_HEIGHT = 292;
