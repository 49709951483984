<template>
  <div class="batch-cases-amount-picker">
    <v-text-field
      v-if="isInputVisible"
      :value="inputValue"
      outlined
      type="number"
      label="Amount"
      hide-details
      hide-spin-buttons
      class="batch-cases-amount-picker__input"
      @input="handleInputChange"
    >
    </v-text-field>
    <v-select
      :value="amountType"
      :items="selectItems"
      :menu-props="{ bottom: true, offsetY: true }"
      :label="!isInputVisible ? 'Amount' : null"
      outlined
      hide-details
      class="batch-cases-amount-picker__select"
      @change="handleSelectChange"
    />
  </div>
</template>

<script>
import { debounce } from "lodash";
import { BatchTypes } from "@/misc/constants";

const AmountTypes = {
  all: "all",
  percent: "percent",
  quantity: "quantity",
};

const SpecialAuditItems = [
  {
    value: {
      type: AmountTypes.percent,
      isInputAvailable: true,
    },
    text: "Pct, %",
  },
  {
    value: {
      type: AmountTypes.quantity,
      isInputAvailable: true,
    },
    text: "Qty",
  },
  {
    value: {
      type: AmountTypes.all,
    },
    text: "All cases",
  },
];

const DocReviewItems = [
  {
    value: {
      type: AmountTypes.percent,
      isInputAvailable: true,
    },
    text: "Pct, %",
  },
  {
    value: {
      type: "category",
    },
    text: "Category based",
    disabled: true,
  },
];

export default {
  name: "BatchCasesAmountPicker",

  props: {
    value: {
      type: Object,
      default: null,
    },
    batchType: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      amountType: null,
      inputValue: null,
    };
  },

  computed: {
    selectItems() {
      return this.batchType === BatchTypes.specialAudit
        ? SpecialAuditItems
        : DocReviewItems;
    },
    isInputVisible() {
      if (!this.amountType) return null;
      return this.amountType?.isInputAvailable;
    },
  },

  mounted() {
    this.amountType = this.selectItems[0].value;
  },

  methods: {
    handleSelectChange(value) {
      this.amountType = value;
      this.inputValue = null;
      this.handleDataChange();
    },
    handleInputChange: debounce(function (value) {
      const newValue = !value || !value.length ? null : value;
      this.inputValue = newValue;
      this.handleDataChange();
    }, 250),
    handleDataChange() {
      if (this.amountType.type !== AmountTypes.all && !this.inputValue) {
        return this.$emit("change", null);
      }
      const data = {
        type: this.amountType.type,
        ...(this.isInputVisible ? { value: this.inputValue } : {}),
      };
      this.$emit("change", data);
    },
  },
};
</script>

<style lang="scss">
.batch-cases-amount-picker {
  width: 100%;
  display: flex;

  &__input {
    width: 40%;
    border-right: none;
    border-radius: 4px 0px 0px 4px;
  }

  &__select {
    width: 60%;
    border-radius: 0px 4px 4px 0px;
  }
}
</style>
